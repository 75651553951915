@use 'sass:map';
@use '../../theme/spacing' as *;
@use '../../theme/mediaqueries' as *;
@use '../../theme/font-weight' as *;

$sizes: (
  xs: (
    mobile: 0.625rem,
    desktop: 0.75rem,
  ),
  s: (
    mobile: 0.75rem,
    desktop: 0.875rem,
  ),
  m: (
    mobile: 1rem,
    desktop: 1rem,
  ),
  l: (
    mobile: 1rem,
    desktop: 1.125rem,
  ),
);

%text-styles {
  display: block;
  font-style: normal;
  font-weight: $font-weight-regular;
  line-height: 170%;
  white-space: pre-wrap;
}

.text {
  @extend %text-styles;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

@each $size, $values in $sizes {
  .size-#{$size} {
    font-size: map.get($values, mobile);

    @media (min-width: $mq-desktop) {
      font-size: map.get($values, desktop);
    }
  }
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: $font-weight-bold;
}

.spacing:not(:last-child) {
  margin-bottom: $spacing-xs;
}
