@use '@lichtblick/photon/theme/global' as *;
@use '@lichtblick/photon/atoms/text/text.module' as *;

.footer {
  overflow: hidden;
  border-top: 1px solid rgba($color-white, 0.2);
  background-color: $color-black;
  color: $color-white;
}

.footer-top {
  border: none;

  @media (min-width: $mq-medium-and-above) {
    padding-top: 2rem;
  }
}

.footer-section {
  display: flex;
  flex-direction: column;
  max-width: #{$breakpoint-large}px;
  margin-inline: auto;

  @media (min-width: $mq-medium-and-above) {
    padding-inline: $spacing-xl;
  }

  padding-top: 1rem;
  padding-bottom: 2rem;
  padding-inline: $spacing-xxs;

  @media (min-width: $mq-small-and-above) {
    flex-wrap: wrap;
  }

  @media (min-width: $mq-medium-and-above) {
    margin: 0 auto;
    padding-inline: calc(#{$spacing-xl} - #{$spacing-xxs});
  }
}

.footer-section-secondary {
  font-size: 0.625rem;
}

.footer-section:not(.footer-section-secondary) {
  @media (min-width: $mq-small-and-above) {
    flex-direction: row;
  }
}

.contact {
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0 0.5rem;

  @media (min-width: $mq-small-and-above) {
    order: 1;
    width: 50%;
  }

  @media (min-width: $mq-medium-and-above) {
    order: 2;
    width: 33.3333%;
  }
}

.contact-headline {
  display: block;
  margin-bottom: 1rem;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: 1.5rem;
  }
}

.contact-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;

  a {
    font-size: 1rem;
  }

  svg {
    /* stylelint-disable-next-line value-keyword-case */
    fill: currentColor;
  }
}

.contact-opening-hours {
  display: block;
  margin-top: 1rem;
  color: $color-gray;
}

.menu {
  display: flex;
  flex: 1;
  flex-direction: column;
  order: 3;
  padding: 0;

  @media (min-width: $mq-small-and-above) {
    flex-direction: row;
  }
}

.menu-secondary {
  order: initial;
  padding: 1rem 0;
}

.primary-nav {
  box-sizing: border-box;
  flex: 1;
  margin: 2rem 0;
  padding: 0 0.5rem;
  list-style: none;
}

.primary-nav-headline {
  display: inline-block;
  margin-bottom: 1rem;

  @media (min-width: $mq-medium-and-above) {
    margin-bottom: 1.5rem;
  }
}

.primary-nav-menu {
  display: flex;
  flex-direction: column;

  li {
    list-style: none;
  }

  a {
    font-size: 1rem;
  }
}

.primary-nav-item {
  box-sizing: border-box;
  flex: 1;
  list-style: none;

  a {
    display: inline-block;
    margin-bottom: 1rem;

    @media (min-width: $mq-medium-and-above) {
      margin-bottom: 1.25rem;
    }
  }
}

.secondary-nav-home-link {
  align-self: center;
  width: 7rem;
  min-height: 0;

  @media (min-width: $mq-medium-and-above) {
    width: 7.8125rem;
  }
}

.copyright {
  display: block;
  margin-top: 1rem;
  color: $color-dark-gray;
  text-align: center;
}

.secondary-nav {
  display: flex;
  box-sizing: border-box;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  padding: 0 0.5rem;
  gap: $spacing-s;

  @media (min-width: $mq-small-and-above) {
    flex-flow: row wrap;
    gap: $spacing-m;
    justify-content: center;
  }

  a {
    font-size: 0.625rem;

    @media (min-width: $mq-medium-and-above) {
      font-size: 0.75rem;
    }
  }
}

.brand-text-container {
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0 0.5rem;

  @media (min-width: $mq-small-and-above) {
    width: 50%;
    margin-right: 50%;
  }

  @media (min-width: $mq-medium-and-above) {
    margin-right: 16.6666%;
  }
}

.home-link {
  display: block;
  width: 12.75rem;
  min-height: 0;
  margin-bottom: 1.75rem;

  @media (min-width: $mq-medium-and-above) {
    width: 16.5rem;
    margin-bottom: 2rem;
  }
}

.logo {
  [class*='logo-sun'] {
    transition: transform $small-intro;

    *:focus-visible > &,
    *:hover > & {
      transform: translate(0.25rem, -0.125rem);
    }

    *:active > & {
      transform: translate(0.625rem, -0.3125rem);
    }
  }
}

.teaser {
  box-sizing: border-box;
  margin: 2rem 0;
  padding: 0 0.5rem;

  @media (min-width: $mq-small-and-above) {
    order: 2;
    width: 50%;
  }

  @media (min-width: $mq-medium-and-above) {
    order: 1;
    width: 33%;
  }
}

.mapper-p {
  @extend %text-styles;

  color: $color-gray;

  &:not(:last-child) {
    margin-bottom: $spacing-xxs;
  }
}

.mapper-a.mapper-a {
  color: $color-white;
  font-size: 1rem;
}
