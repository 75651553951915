@use '@lichtblick/photon/theme/global' as *;

.link.link {
  display: block;

  @media (min-width: $mq-tablet-and-above) {
    display: none;
  }
}

.container {
  display: none;
  position: relative;
  align-self: center;

  @media (min-width: $mq-tablet-and-above) {
    display: block;
  }
}

.input {
  @include focus-ring;

  appearance: none;
  display: block;
  width: 5em;
  padding: 0 $spacing-3xs 0 $spacing-s;
  transition:
    color $large-outro,
    width $large-outro;
  border: none !important;
  border-radius: $border-radius-s;
  background: none;
  font-size: 1rem;
  line-height: 2.25rem;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    display: none;
  }

  &:hover {
    background: $color-light-gray;
  }

  &::placeholder {
    color: $color-black;
  }

  &:focus {
    width: 16rem;
    border: solid 1px $color-light-gray;
    background: $color-white;

    &::placeholder {
      color: $color-gray;
    }
  }

  &:not(:focus) {
    cursor: pointer !important;
  }
}

.icon {
  position: absolute;
  /* stylelint-disable-next-line meowtec/no-px */
  top: calc(50% - 8px); // half icon height
  left: $spacing-3xs;
  pointer-events: none;
}
