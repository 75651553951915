@use '../../theme/colors' as *;
@use '../../theme/spacing' as *;
@use '../../theme/animations' as *;
@use '../../theme/z-indexes' as *;
@use '../../theme/border' as *;
@use '../../theme/mediaqueries' as *;

@keyframes overaly-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes overaly-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes content-mobile-in {
  0% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }

  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes content-mobile-out {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 20%);
    opacity: 0;
  }
}

@keyframes content-desktop-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes content-desktop-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.overlay {
  position: fixed;
  z-index: $z-index-modal;
  background-color: rgba($color-black, 0.8);
  inset: 0;

  &[data-state='open'] {
    animation: $large-outro overaly-in;
  }

  &[data-state='closed'] {
    animation: $large-outro overaly-out;
  }
}

.modal-surface {
  display: flex;
  position: fixed;
  z-index: $z-index-modal;
  bottom: 0;
  left: 50%;
  flex-direction: column;
  width: 100vw;
  max-height: 80vh;
  overflow: hidden;
  transform: translate(-50%, 0);
  border-radius: $border-radius-m $border-radius-m 0 0;
  background: $color-white;
  box-shadow:
    hsl(206deg 22% 7% / 35%) 0 0.625rem 2.375rem -0.625rem,
    hsl(206deg 22% 7% / 20%) 0 0.625rem 1.25rem -1rem;

  &:focus {
    outline: none;
  }

  &[data-state='open'] {
    animation: $large-outro content-mobile-in;
  }

  &[data-state='closed'] {
    animation: $large-outro content-mobile-out;
  }

  @media (min-width: $mq-desktop) {
    top: 50%;
    bottom: unset;
    width: 90vw;
    max-width: 60.6665rem;
    transform: translate(-50%, -50%);
    border-bottom-right-radius: $border-radius-m;
    border-bottom-left-radius: $border-radius-m;

    &[data-state='open'] {
      animation: $large-outro content-desktop-in;
    }

    &[data-state='closed'] {
      animation: $large-outro content-desktop-out;
    }
  }
}

.content-wrapper {
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 0.375rem;
    height: 0.375rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius-xxs;
    background-color: $color-gray;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

.modal-content {
  padding: $spacing-s $spacing-xs 0;

  @media (min-width: $mq-desktop) {
    padding: $spacing-xl 4.5rem 0;
  }
}

.bottom-shadow-curtain {
  display: block;
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  width: 100%;
  height: 3rem;
  background: $color-white;
  background: linear-gradient(0deg, $color-white 0%, rgb(255 255 255 / 0%) 100%);
}

.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: $spacing-xs;
  border-top: 0.0625rem solid $color-light-gray;
  background-color: $color-white;
  box-shadow:
    rgba($color-black, 0.08) 0 0 0.5rem,
    rgba($color-black, 0.08) 0 -1rem 2.5rem;
  gap: $spacing-s;

  @media (min-width: $mq-desktop) {
    flex-direction: row-reverse;
    padding: $spacing-s;
    border-bottom-right-radius: $border-radius-m;
    border-bottom-left-radius: $border-radius-m;
    gap: $spacing-xl;
  }
}
