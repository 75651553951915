@use '@lichtblick/photon/theme/global' as *;

.text-layer {
  display: flex;
  flex-direction: column;
  gap: $spacing-xxs;

  @media (min-width: $mq-medium-and-above) {
    gap: $spacing-xs;
  }

  &-color-white {
    color: $color-white;
  }

  &-color-black {
    color: $color-black;
  }

  &-layout {
    &-lower-third {
      grid-area: lower-third;
      justify-content: flex-end;
    }

    &-middle-third {
      grid-area: middle-third;
      justify-content: center;
    }

    &-upper-third {
      grid-area: upper-third;
      justify-content: flex-start;
    }
  }
}
