$easing-intro: cubic-bezier(0.4, 0, 0.2, 1);
$easing-outro: cubic-bezier(0, 0.4, 0.2, 1);

$duration-large-intro: 350;
$duration-large-outro: 250;
$duration-small-intro: 200;
$duration-small-outro: 150;

$large-intro: #{$duration-large-intro}ms $easing-intro;
$large-outro: #{$duration-large-outro}ms $easing-outro;
$small-intro: #{$duration-small-intro}ms $easing-intro;
$small-outro: #{$duration-small-outro}ms $easing-outro;

/* stylelint-disable */
:export {
  durationLargeIntro: $duration-large-intro;
  durationLargeOutro: $duration-large-outro;
  durationSmallIntro: $duration-small-intro;
  durationSmallOutro: $duration-small-outro;
}
