@use '@lichtblick/photon/theme/global' as *;

.image-wrapper {
  position: relative;
  width: 2.4rem;
  height: 2.4rem;
  overflow: hidden;
  border-radius: 625rem;
}

.info-lines {
  display: flex;
  flex-direction: column;
  gap: $spacing-xs;
  align-items: center;

  @media (min-width: $mq-small-and-above) {
    flex-direction: row;
    justify-content: center;
    gap: $spacing-s;
    width: 100%;
  }
}

.info-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-3xs;
}

.image-info-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $spacing-xxs;
}
