@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

$close-button-height: 3.5rem;

@mixin divider($backgroundColor: none) {
  &::after {
    content: '';
    display: block;
    block-size: 1px;
    /* stylelint-disable-next-line meowtec/no-px */
    margin: 4px 0;
    background: $backgroundColor;
  }
}

@mixin list-item {
  width: 100%;
  padding: $spacing-xxs $spacing-m $spacing-xxs $spacing-xs;
  border-radius: $border-radius-s;
  background: none;
  text-align: left;

  @include focus-ring;

  @media (min-width: $mq-tablet-and-above) {
    padding: $spacing-3xs $spacing-xxs;
  }

  &:hover {
    background: color-mix(in srgb, $color-black 11%, transparent);
  }
}

.container {
  display: flex;
  align-items: center;
}

.scroll-wrapper {
  display: grid;
  overflow: hidden auto;

  @media (min-width: $mq-tablet-and-above) {
    overflow: visible;
  }

  &:has(.nav-level-1-active) {
    overflow: hidden;

    @media (min-width: $mq-tablet-and-above) {
      overflow: visible;
    }
  }
}

.nav {
  display: grid;
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  grid-template-rows: $close-button-height 1fr auto auto;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  transform: translate3d(-100%, 0, 0);
  transition:
    transform 0.2s linear,
    visibility 0.2s linear;
  background: $color-white;

  @media (min-width: $mq-tablet-and-above) {
    display: block;
    visibility: visible;
    position: relative;
    width: auto;
    height: auto;
    overflow: visible;
    transform: none;
    transition: none;
    background: none;
  }

  ul {
    list-style: none;
  }

  &-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);

    @media (min-width: $mq-tablet-and-above) {
      transform: none;
    }
  }

  &-close-button {
    padding: $spacing-xs $spacing-s;
    background: none;
    justify-self: start;
    line-height: 0;

    @include focus-ring;

    @media (min-width: $mq-tablet-and-above) {
      display: none;
    }
  }

  &-hidden {
    display: none;

    @media (min-width: $mq-tablet-and-above) {
      display: block;
    }
  }
}

.nav-level-0 {
  display: grid;
  visibility: hidden;
  position: relative;
  grid-template-rows: auto 1fr auto auto;
  padding: $spacing-s;
  transform: translate3d(-100%, 0, 0);
  transition:
    transform 0.2s linear,
    visibility 0.2s linear;
  gap: $spacing-xs;

  @media (min-width: $mq-tablet-and-above) {
    display: block;
    visibility: visible;
    padding: 0;
    transform: none;
    transition: none;
    background: none;
  }

  &-active {
    visibility: visible;
    transform: translate3d(0, 0, 0);

    @media (min-width: $mq-tablet-and-above) {
      transform: none;
    }
  }

  &-button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    gap: $spacing-xxs;

    @include list-item;

    @media (min-width: $mq-tablet-and-above) {
      display: block;
      padding: $spacing-3xs $spacing-xxs;
      border-radius: $border-radius-s;
      background: none;

      @include focus-ring;

      &:hover {
        background: color-mix(in srgb, $color-black 11%, transparent);
      }

      &::after {
        content: '';
        display: block;
        visibility: hidden;
        position: absolute;
        /* stylelint-disable-next-line meowtec/no-px */
        top: 71px;
        left: 50%;
        width: 0.75rem;
        height: 0.75rem;
        transform: translate3d(0, 0.5rem, 0);
        rotate: 45deg;
        transition:
          opacity 0.1s linear 0.2s,
          transform 0.1s linear 0.2s;
        border-radius: 3px 0 0;
        opacity: 0;
        background: linear-gradient(to bottom right, $color-white 50%, transparent 0);
        cursor: default;
      }

      svg {
        display: none;
      }
    }
  }

  & &-meta-button {
    @media (min-width: $mq-tablet-and-above) {
      display: none;
    }
  }

  &-list {
    @media (min-width: $mq-tablet-and-above) {
      display: flex;
      gap: 0.875rem;

      :has(.nav-level-1-active) .nav-level-0-button {
        background: color-mix(in srgb, $color-black 11%, transparent);

        &::after {
          visibility: visible;
          transform: translate3d(-50%, 0, 0);
          opacity: 1;
        }
      }
    }

    @media (min-width: $mq-medium-and-above) {
      gap: $spacing-s;
    }
  }

  &-list-item {
    @media (max-width: $mq-below-tablet) {
      @include divider($color-light-gray);
    }

    &:not(:nth-child(1), :nth-child(2), :nth-last-child(2), :last-child) {
      @media (min-width: $mq-tablet-and-above) {
        .nav-level-1 {
          left: 50%;
          transform: translate3d(-50%, 0, 0);
        }
      }
    }

    &:nth-last-child(2),
    &:last-child {
      @media (min-width: $mq-tablet-and-above) {
        .nav-level-1 {
          right: 0;
          left: auto;
        }
      }
    }

    &-active > button {
      font-weight: $font-weight-bold;

      @media (max-width: $mq-below-tablet) {
        background: color-mix(in srgb, $color-black 11%, transparent);
      }

      @media (min-width: $mq-tablet-and-above) {
        &::before {
          content: '';
          display: block;
          position: absolute;
          top: $spacing-m;
          left: calc(50% - math.div($spacing-m, 2));
          width: $spacing-m;
          height: 2px;
          border-radius: 1px;
          background: $color-black;
        }
      }
    }
  }
}

.nav-level-1 {
  display: grid;
  visibility: hidden;
  position: absolute;
  inset: 0;
  grid-auto-rows: max-content auto max-content;
  height: calc(100vh - $close-button-height);
  height: calc(100dvh - $close-button-height);
  overflow-y: auto;
  transform: translate3d(100%, 0, 0);
  transition:
    transform 0.2s linear,
    visibility 0.2s linear;
  background: $color-white;

  @media (min-width: $mq-tablet-and-above) {
    top: 4.5rem;
    right: unset;
    bottom: unset;
    left: 0;
    grid-template:
      'header media' auto
      'content media' 1fr / max-content max-content;
    width: auto;
    height: auto;
    transform: none;
    transition:
      opacity 0.2s linear,
      visibility 0.2s linear;
    border-radius: $border-radius-m;
    opacity: 0;
    background: $color-faded-smoke;
    /* stylelint-disable-next-line meowtec/no-px */
    box-shadow: -10px 10px 30px 0 color-mix(in srgb, $color-black 11%, transparent);
  }

  &-active {
    visibility: visible;
    transform: translate3d(100%, 0, 0);

    @media (min-width: $mq-tablet-and-above) {
      transform: none;
      opacity: 1;
    }
  }

  &-header {
    padding: $spacing-s $spacing-s 0;

    @include divider($color-light-gray);

    @media (min-width: $mq-tablet-and-above) {
      grid-area: header;
      padding: $spacing-l $spacing-s 0;
      background: $color-white;

      &::after {
        display: none;
      }
    }

    @media (min-width: $mq-medium-and-above) {
      grid-area: header;
      padding: $spacing-l $spacing-l 0;
      background: $color-white;

      &::after {
        display: none;
      }
    }
  }

  &-back-button {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: $spacing-xxs;
    font-weight: $font-weight-bold;

    @include list-item;

    @media (max-width: $mq-below-tablet) {
      padding: $spacing-xxs $spacing-m $spacing-xxs 0;
    }

    @media (min-width: $mq-tablet-and-above) {
      display: none;
    }
  }

  span#{&}-title {
    display: none;

    @media (min-width: $mq-tablet-and-above) {
      display: block;
    }
  }
}

.nav-groups-list {
  padding: 0 $spacing-s $spacing-s;

  @media (min-width: $mq-tablet-and-above) {
    grid-area: content;
    padding: $spacing-s $spacing-s $spacing-l;
    column-gap: $spacing-xxl;
    column-rule: solid 1px $color-light-gray;
    background: $color-white;
  }

  @media (min-width: $mq-medium-and-above) {
    padding: $spacing-s $spacing-l $spacing-l;
  }

  &-columns-1 {
    @media (min-width: $mq-tablet-and-above) {
      max-width: 20.375rem;
      columns: 1;
    }
  }

  &-columns-2 {
    @media (min-width: $mq-tablet-and-above) {
      max-width: 37.375rem;
      columns: 2;
    }
  }

  &-item {
    break-inside: avoid;

    @media (min-width: $mq-tablet-and-above) {
      &:not(:last-child, :has(.nav-groups-list-title)) .nav-group-list-item:last-child::after {
        margin: $spacing-xs 0;
        background: $color-light-gray;
      }
    }
  }

  &-title {
    color: $color-dark-gray;
    font-size: 0.875rem;

    @include divider($color-light-gray);

    @media (min-width: $mq-tablet-and-above) {
      font-size: 1rem;

      @include divider;
    }

    span {
      display: block;
      width: 100%;
      padding: $spacing-xxs 0 $spacing-xxs 0;
      border-radius: $border-radius-s;
      background: none;
      text-align: left;
    }

    &-additional-items {
      @media (min-width: $mq-tablet-and-above) {
        display: none !important;
      }
    }
  }
}

.nav-group-list {
  &-item {
    &:not(:has(ul), :last-child > ul > li:last-child) {
      @include divider($color-light-gray);

      @media (min-width: $mq-tablet-and-above) {
        @include divider;
      }
    }

    a {
      border-radius: $border-radius-s;
    }

    &-active a {
      background: color-mix(in srgb, $color-black 11%, transparent);
      font-weight: $font-weight-bold;
    }
  }

  &-link {
    display: block;

    @include list-item;
  }
}

.image {
  display: flex;
  align-items: center;
  padding: $spacing-m;
  background: $color-faded-smoke;

  @media (min-width: $mq-tablet-and-above) {
    grid-area: media / media;
    padding: $spacing-l $spacing-l $spacing-l 0;
    background: $color-white;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: $border-radius-s;

    @media (min-width: $mq-tablet-and-above) {
      width: 19.75rem;
      height: 19.75rem;
      object-fit: cover;
      border-radius: $border-radius-s;
    }
  }
}

.teaser-list {
  display: flex;
  padding: $spacing-m;
  overflow: hidden;
  gap: $spacing-s;
  overflow-x: auto;
  background: $color-faded-smoke;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;

  @media (hover: none) {
    // Hides scrollbar in firefox
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @media (min-width: $mq-tablet-and-above) {
    display: grid;
    grid-area: media / media;
    align-self: center;
    padding: $spacing-l $spacing-s;
    gap: $spacing-s;
  }

  @media (min-width: $mq-medium-and-above) {
    padding: $spacing-l;
  }

  &-columns-1 {
    @media (min-width: $mq-tablet-and-above) {
      grid-template-columns: 1fr;
    }
  }

  &-columns-2 {
    @media (min-width: $mq-tablet-and-above) {
      grid-template-columns: 1fr 1fr;
    }
  }

  & &-item {
    display: flex;
    flex-direction: column;
    gap: $spacing-xxs;
    scroll-snap-align: center;
    flex-shrink: 0;
    /* stylelint-disable-next-line meowtec/no-px */
    width: 184px;

    span {
      font-size: 0.75rem;
    }

    &-image {
      display: block;
      /* stylelint-disable-next-line meowtec/no-px */
      height: 112px;

      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.burger-menu-button {
  display: grid;
  /* stylelint-disable-next-line meowtec/no-px */
  padding: 4px;
  background: none;

  @include focus-ring;

  @media (min-width: $mq-tablet-and-above) {
    display: none;
  }
}
