@use '@lichtblick/photon/theme/global' as *;

.image {
  &,
  & > img {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &-is-background {
    height: 100%;
  }

  @media (min-width: $mq-small-and-above) {
    display: none;
  }
}

.button {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: calc(50% - 1.75rem);
    left: calc(50% - 1.75rem);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 1.75rem;
    background: $color-orange;
  }

  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 0.6rem);
    left: calc(50% - 0.35rem);
    border-top: solid 0.6rem transparent;
    border-bottom: solid 0.6rem transparent;
    border-left: solid 1rem $color-white;
  }

  &:hover {
    &::before,
    &::after {
      background-image: linear-gradient(0deg, rgb(0 0 0 / 11%), rgb(0 0 0 / 11%));
    }
  }

  &:active {
    &::before,
    &::after {
      transform: scale(0.95);
    }
  }

  &:focus-visible {
    @include focus-ring;
  }
}

.video {
  display: none;
  width: 100%;
  height: auto;
  object-fit: cover;

  &-is-visible {
    display: block;
  }

  &-is-background {
    height: 100%;
  }
}
