@use '@lichtblick/photon/theme/global' as *;

.thanks {
  @include content-container;
  @include padding-classes;

  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;

  @media (min-width: $mq-medium-and-above) {
    display: flex;
    flex-direction: row-reverse;
    padding-top: $spacing-s;
    padding-bottom: $spacing-m;
  }

  .image {
    @media (min-width: $mq-medium-and-above) {
      width: 50%;
      padding: 0 $spacing-xxs;
    }
  }

  .info-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $spacing-xs 0 0;

    @media (min-width: $mq-medium-and-above) {
      width: 50%;
      padding: 0 $spacing-xl 0 $spacing-s;
    }
  }

  .headline {
    margin-bottom: $spacing-xxs;
  }

  .text {
    margin-bottom: $spacing-xxs;
  }

  .list {
    margin-top: $spacing-xxs;

    & > :not(:last-of-type) {
      margin-bottom: $spacing-xs;
    }
  }

  .list-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    list-style: none;
  }

  .icon {
    flex-shrink: 0;
    margin-top: 0.125rem;
    margin-right: $spacing-xs;

    @media (min-width: $mq-medium-and-above) {
      margin-top: 0.25rem;
    }
  }
}

.rating {
  @include content-container;
  @include padding-classes;

  @media (min-width: $mq-medium-and-above) {
    padding-right: calc(
      max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 12
    );
    padding-left: calc(
      max(3.5rem, calc((100% - #{$breakpoint-large}px) / 2 + 3.5rem)) + min(100%, #{$breakpoint-large}px) / 12
    );
  }

  padding-top: $spacing-l;
  padding-bottom: $spacing-l;
  background-color: $color-gray-100;

  .stars {
    margin-bottom: $spacing-xs;
    text-align: center;

    :global(.shift) {
      fill: $color-orange-300;
    }
  }

  .headline {
    margin-bottom: $spacing-xxs;
  }

  .text {
    margin-bottom: $spacing-xs;
  }

  .action-box {
    display: flex;
    justify-content: center;

    .button {
      margin: 0 $spacing-xs;
    }
  }
}
