@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.stage {
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    padding-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      background-color: $color-value;

      @include get-text-color($color-value);
    }
  }
}

.text-intro {
  @media (min-width: $mq-medium-and-above) {
    width: math.div(2, 3) * 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
