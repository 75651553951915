@use '@lichtblick/photon/theme/global' as *;

.link {
  display: flex;
  gap: $spacing-3xs;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: $spacing-m;
  height: $spacing-m;
  /* stylelint-disable-next-line meowtec/no-px */
  padding: 4px;
  border-radius: $border-radius-s;

  &:hover {
    background-color: $color-light-gray;
  }

  @include focus-ring;

  @media (min-width: $mq-tablet-and-above) {
    width: auto;
    height: auto;
    padding: $spacing-3xs $spacing-xxs;
  }

  &-active {
    background-color: $color-light-gray;
    font-weight: $font-weight-bold;
  }

  span {
    display: none;

    @media (min-width: $mq-tablet-and-above) {
      display: block;
    }
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    @media (min-width: $mq-tablet-and-above) {
      width: auto;
      height: auto;
    }
  }
}
