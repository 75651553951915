@use '@lichtblick/photon/theme/global' as *;
@use '@lichtblick/photon/molecules/textfield/textfield.shared.module' as *;

.section {
  display: grid;
  padding: 4rem 0;

  @media (min-width: $mq-medium-and-above) {
    grid-template-columns: 1fr 1fr;
  }

  @include content-container;
}

.column-left {
  background: $color-aqua-700;
  color: $color-aqua-200;
  row-gap: $spacing-s;

  &-content {
    display: grid;
    row-gap: $spacing-s;
    padding: $spacing-s;
  }
}

.column-right {
  display: grid;
  grid-auto-rows: max-content;
  padding: $spacing-xl $spacing-l;
  background: $color-faded-gray;
  gap: $spacing-xs;
}

.badge {
  padding: 0 $spacing-xs;
  border-radius: $border-radius-m;
  background-color: $color-orange;
  color: $color-aqua-700;
  font-style: italic;
  justify-self: self-start;
  text-align: center;
}

.rich-text {
  color: $color-orange;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-aqua-200;
  }
}

.form {
  flex: 1;

  form,
  :global(.hs-dependent-field) {
    display: flex;
    flex-flow: column nowrap;
    row-gap: $spacing-xs;
    width: 100%;
  }

  :global(.field) {
    width: 100%;
  }

  ul {
    list-style-type: none;
  }

  fieldset {
    display: flex;
    justify-content: stretch;
    width: 100%;
    max-width: 100% !important;
    gap: $spacing-xs;
  }

  label:not(:global(.hs-form-booleancheckbox-display), :global(.hs-error-msg)) {
    display: block;
    margin-bottom: $spacing-3xs;
    font-size: 1rem;
    font-weight: 600;
    line-height: 170%;
  }

  :global(.input):has(> select),
  :global(.input):has(> textarea) {
    margin-right: 0 !important;
  }

  :global(.input):has(> input:not([type='checkbox'], [type='hidden'])),
  select,
  textarea {
    display: flex;
    width: 100% !important;
    height: 2.6875rem;
    border: 2px solid $color-black;
    border-radius: $border-radius-s;

    @include shared-input-row-base;

    &:has(> :global(.error)) {
      @include shared-input-row-error;
    }

    input:not([type='checkbox'], [type='hidden']) {
      @include shared-input-base;
    }
  }

  select,
  textarea {
    width: 100%;
    padding-right: $spacing-xs;
    padding-left: $spacing-xs;
    background-color: $color-white;
    color: $color-black;
    font-family: inherit;
    font-size: 1rem;
  }

  textarea {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
  }

  :global(.hs-error-msg),
  :global(.hs-error-msgs) {
    color: $state-color-error;
    font-size: 0.75rem;
  }

  :global(.hs-form-booleancheckbox-display) {
    display: flex;
    flex-flow: row nowrap;
    gap: 0.5rem;

    > span {
      margin-left: 0 !important;
    }
  }

  :global(.hs-richtext) {
    font-size: 0.75rem;
    line-height: 170%;

    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  input[type='submit'] {
    flex-grow: 0;
    width: 100%;
    margin-top: $spacing-xs;
    padding: $spacing-xxs $spacing-xs;
    transition: 0.1s;
    border: none;
    border-radius: $spacing-l;
    background-color: $color-orange;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.7;
    cursor: pointer;

    &:hover {
      background-color: color-mix(in srgb, currentcolor 11%, $color-orange);
    }

    &:active {
      transform: scale(0.98);
      outline: none;
    }
  }

  input[type='checkbox'] {
    display: flex;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: $spacing-xs !important;
    height: $spacing-xs;
    /* stylelint-disable-next-line meowtec/no-px */
    margin-top: 5px;
    appearance: none;
    border: $border-width-input solid $color-black;
    border-radius: $border-radius-xxs;
    outline: none;
    cursor: pointer;

    /* Focus indicator */
    &::before {
      content: '';
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      transition: opacity $small-intro;
      border: 0.375rem solid rgba($color-orange, $opacity-light);
      border-radius: $border-radius-xs;
      opacity: 0;
    }

    /* The checkmark */
    &::after {
      content: '';
      width: 100%;
      height: 100%;
      transition: opacity $small-intro;
      opacity: 0;
      background-image: url("data:image/svg+xml;utf8,<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M1.47339 4.85233L4.09485 6.86554L8.57571 1.13208' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/></svg>");
      background-repeat: no-repeat;
      background-position: center;
    }

    &:focus-visible::before {
      opacity: 1;
    }

    &:checked {
      background: $color-orange;

      &::after {
        opacity: 1;
      }
    }
  }
}
