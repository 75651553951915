@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.container {
  display: block;
  position: relative;
  margin-right: math.div($spacing-m, 2);

  @media (min-width: $mq-tablet-and-above) {
    display: none;
  }
}

.input {
  @include focus-ring;

  appearance: none;
  display: block;
  width: 100%;
  padding: $spacing-xs;
  border: none !important;
  border-radius: $border-radius-s;
  outline: solid 1px $color-gray;
  background: $color-white;
  font-size: 1rem;

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    display: none;
  }

  &::placeholder {
    color: $color-dark-gray;
  }
}

.submit {
  appearance: none;
  display: block;
  position: absolute;
  /* stylelint-disable-next-line meowtec/no-px */
  top: calc(50% - 8px); // half icon height
  right: math.div($spacing-m, 2);
  background-color: transparent;
  line-height: 0;
}
