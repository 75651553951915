@use '@lichtblick/photon/theme/global' as *;

.image {
  max-width: 100%;
  height: auto;
  vertical-align: middle;

  &-is-background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-is-full-width {
    width: 100%;
  }
}
