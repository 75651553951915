@use '@lichtblick/photon/theme/global' as *;

.stage {
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: $mq-medium-and-above) {
    grid-template-columns: 1fr 1fr;
  }

  @each $color-name, $color-value in $cms-colors {
    &-bg-#{'' + $color-name} {
      @include background-text-color($color-value);
    }
  }

  &::before {
    content: ' ';
    background-image: var(--var-image-url-small);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    aspect-ratio: 2 / 1;

    @media (min-width: $mq-tablet-and-above) {
      background-image: var(--var-image-url-medium);
    }

    @media (min-width: $mq-medium-and-above) {
      aspect-ratio: unset;
      grid-column: 2 / 2;
      grid-row: 1;
      background-image: var(--var-image-url-large);
    }
  }
}

.content-container {
  display: grid;

  @media (min-width: $mq-medium-and-above) {
    grid-column: 1 / 3;
    grid-row: 1;
    grid-template-columns: 1fr 1fr;

    @include content-container;
  }
}

.content {
  display: grid;
  margin: $spacing-m $spacing-s;
  row-gap: $spacing-xs;
  justify-items: start;
  padding-bottom: $spacing-xxs;

  @media (min-width: $mq-medium-and-above) {
    margin: $spacing-xl $spacing-xl $spacing-xl 0;
    padding-bottom: $spacing-xs;
  }

  .headline-color {
    @each $color-name, $color-value in $cms-colors {
      &-#{'' + $color-name} {
        color: $color-value;
      }
    }
  }

  .list {
    & > :not(:last-of-type) {
      margin-bottom: $spacing-xxs;
    }
  }

  .list-item {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    width: 100%;
    list-style: none;
  }

  .icon {
    flex-shrink: 0;
    margin-top: 0.375rem;
    margin-right: $spacing-xs;
  }

  .button {
    margin-top: $spacing-xs;
  }
}
