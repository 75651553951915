@use '@lichtblick/photon/theme/global' as *;

.header {
  display: grid;
  position: relative;
  z-index: $z-index-header;
  grid-template:
    'logo logo logo logo logo logo logo logo logo' auto
    '. navigation . search-link . contact-link . account-link .' auto
    / $spacing-xs 1fr $spacing-xs max-content $spacing-xs max-content $spacing-xs max-content $spacing-xs;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0.75rem;

  @media (min-width: $mq-tablet-and-above) {
    grid-template:
      'logo search-link contact-link account-link' auto
      'navigation navigation navigation navigation' auto
      / 1fr max-content max-content max-content;
    padding-top: 0.75rem;
    padding-bottom: 0;
    column-gap: $spacing-xs;

    @include content-container;
  }
}

.search-link {
  grid-area: search-link;
}

.contact-link {
  grid-area: contact-link;
}

.account-link {
  grid-area: account-link;
}

.home-link {
  display: grid;
  grid-area: logo;
  justify-self: start;
  margin: $spacing-3xs;
  padding: $spacing-xxs;
  border-radius: $border-radius-s;

  @include focus-ring;

  @media (min-width: $mq-tablet-and-above) {
    margin: 0;
  }
}

.logo {
  height: 1.75rem;

  @media (min-width: $mq-tablet-and-above) {
    height: 2.25rem;
  }

  :global(.logo-sun) {
    transition: transform $small-outro;
  }

  &:focus-visible > :global(.logo-sun),
  &:hover > :global(.logo-sun) {
    transform: translate(0.25rem, -0.125rem);
    transition: transform $small-intro;
  }

  &:active > :global(.logo-sun) {
    transform: translate(0.625rem, -0.3125rem);
  }
}

.nav {
  grid-area: navigation;

  @media (min-width: $mq-tablet-and-above) {
    padding: $spacing-s 0;
  }
}
