@use 'sass:math';
@use '@lichtblick/photon/theme/global' as *;

.section {
  scroll-margin-top: 8rem;
  padding: $spacing-m 0;

  @media (min-width: $mq-medium-and-above) {
    padding: $spacing-xl 0;
  }
}

.banner {
  display: grid;
  justify-items: center;
  padding-top: $spacing-m;
  padding-bottom: $spacing-3xl;
  background: $color-moss;
  row-gap: $spacing-xs;
  color: $color-white;
  text-align: center;

  @include content-container;

  @media (min-width: $mq-medium-and-above) {
    & > * {
      margin-right: math.div(100%, 12);
      margin-left: math.div(100%, 12);
    }
  }
}

.cards {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding-bottom: $spacing-m;
  background: $color-faded-gray;
  gap: $spacing-m;

  @media (min-width: $mq-medium-and-above) {
    flex-flow: row nowrap;
    gap: $spacing-xxl;
  }

  @include content-container;
}

.card {
  &:first-child {
    margin-top: -$spacing-xl;
  }

  @media (min-width: $mq-medium-and-above) {
    margin-top: -$spacing-xl;
  }
}
