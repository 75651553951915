@use '@lichtblick/photon/theme/global' as *;

.max-width {
  @media (max-width: $mq-below-medium) {
    width: 64vw;
    /* stylelint-disable-next-line meowtec/no-px */
    max-width: 320px;
    margin: 0 auto;
  }
}

.svg {
  display: block;
  width: 100%;
  height: auto;
}
